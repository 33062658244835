
class ShopPage extends BasePage {
    
    constructor(params, app) {
        super(params, app);
    }
    
    async content() {
        
        switch (this.params.system) {
            case 'android':
            default:
                var shop_button = `<a href="${app.android_store_link}">
                    <button class="btn btn-dark app-button mb-3 d-flex flex-row" >
                        <i class="fa fa-play"></i>
                        <div class="d-flex flex-column w-100">
                            <span>Download the app</span>
                            <h3 class="mb-0">Google Store</h3>
                        </div>
                    </button>
                </a>`;
                break;
            case 'ios':
                var shop_button = `<a href="${app.apple_store_link}">
                    <button class="btn btn-dark app-button mb-3 d-flex flex-row" >
                        <i class="fa fa-apple"></i>
                        <div class="d-flex flex-column w-100">
                            <span>Download the app</span>
                            <h3 class="mb-0">App Store</h3>
                        </div>
                    </button>
                </a>`;
                break;
        }
        
        return ` 
            <div class="store_buttons">
                <a href="${this.params.full_url}">
                    <button class="btn btn-info app-button mb-3 d-flex flex-row" >
                        <i class="fa fa-refresh"></i>
                        <div class="d-flex flex-column w-100">
                            <span>refresh</span>
                            <h3 class="mb-0">site</h3>
                        </div>
                    </button>
                </a>
                
                ${shop_button}
            </div>
        `;
    }
    
    async js() {
        var self = this;
    }
};