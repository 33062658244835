
class BasePage {
    
    constructor(params, app) {
        this.params = params || {};
        this.layoutName = 'home';
        this.dom = $('#app');
        this.app = app;
        this.observerIds = [];
    }
    
    async content() {
        
    }

    async layout_home(content) {
        return `
            <div class="alert-container" >
                <div class="alert alert-danger app-error" role="alert" style="display: none"></div>
                <div class="alert alert-success app-message" role="alert" style="display: none"></div>
            </div>
            <div class="container" >
                <div class="content" >
                    ${content}
                </div>
            </div>
        `;
    }
    
    wait() {
        $('.content').hide();
        $('.content-wait').show().wait();
    }
    
    unwait() {
        $('.content').show();
        $('.content-wait').html('').hide();
    }
    
    sleep(ms) {
       return new Promise(resolve => setTimeout(resolve, ms));
     }
    
    async render() {
        var content = await this.content();
        var html = await this['layout_'+this.layoutName](content);
        this.dom.html(html);
        await this.mainjs();
        await this.js();
    }
    
    async mainjs() {
        var self = this;
    }
    
    observe(names, callback, observer_name = null) {
        if(!observer_name) {
            observer_name = 'page.'+app.page_name;
        }
        var id = eventHandler.observe(observer_name, names, callback);
        this.observerIds.push(id);
    }
    
    async destroy() {
        eventHandler.remove(this.observerIds);
    }   
}

