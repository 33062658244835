
class LoginPage extends BasePage {
    
    constructor(params, app) {
        super(params, app);
    }
    
    async content() {
        return ` 
            <div class="row p-0 m-0">
                LOGIN INSTRUCTIONS
            </div>
        `;
    }
    
    async js() {
        var self = this;
    }
};