class AppEventHandler {
    constructor() {
        this.callbacks = [];
        this.observer_id = 1;
        this.mapIdNames = {};
    }

    observe(observer_name, names, callback) {
        var id = this.observer_id++;
        var observer = {
            id, observer_name, callback
        };

        if (typeof names == 'string') {
            names = [names];
        }
        this.mapIdNames[id] = names;

        for (var name of names) {
            if(!this.callbacks.hasOwnProperty(name)) {
                this.callbacks[name] = {};
            }
            this.callbacks[name][id] = observer;
        }
        
        return id;
    }

    trigger(name, params) {
        if (this.callbacks[name]) {
            for(let id in this.callbacks[name]) {
                try {
                    let observer = this.callbacks[name][id];
                    observer.callback(params);
                } catch (err) {
                    console.log('Event trigger error: ' + name, err);
                }                
            }
        }
    }
    
    remove(id) {
        if(typeof id == 'object') {
            for(let _id of id) {
                this.remove(_id);
            }
            return ;
        }
        if(!this.mapIdNames.hasOwnProperty(id)) {
            return ;
        }
        for(let name of this.mapIdNames[id]) {
            delete this.callbacks[name][id];
        }
        delete this.mapIdNames[id];
    }
}

var eventHandler = new AppEventHandler();
